import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { db } from "../../firebase";
import { doc, getDoc } from "firebase/firestore";
import "./BusinessPage.css";

const BusinessPage = () => {
  const { domain, pageId } = useParams();
  const [business, setBusiness] = useState(null);
  const [error, setError] = useState(null);
  const [activePage, setActivePage] = useState(null);

  useEffect(() => {
    const fetchBusiness = async () => {
      try {
        const docRef = doc(db, "businesses", domain);
        const snapshot = await getDoc(docRef);

        if (!snapshot.exists()) {
          setError("Business not found.");
          return;
        }

        const data = snapshot.data();
        // if (!data.published) { setError("Not published"); return; }
        setBusiness(data);
      } catch {
        setError("Error fetching business data.");
      }
    };
    fetchBusiness();
  }, [domain]);

  useEffect(() => {
    if (business?.pages?.length) {
      const foundPage = business.pages.find((p) => p.id === pageId);
      if (foundPage) {
        setActivePage(foundPage);
      } else {
        // If no pageId or invalid pageId, default to the first page
        setActivePage(business.pages[0]);
      }
    }
  }, [business, pageId]);

  if (error) return <p>{error}</p>;
  if (!business) return <p>Loading...</p>;
  if (!activePage) return <p>This business has no pages defined.</p>;

  return (
    <div style={{ fontFamily: "Arial, sans-serif", margin: 0, padding: 0 }}>
      {/* Banner */}
      <div
        style={{
          backgroundColor: "#eee",
          padding: "1rem",
          textAlign: "center",
        }}
      >
        <h1 style={{ margin: 0 }}>{business.name}</h1>

        {business.content && <p>{business.content}</p>}

        {business.contact && (
          <p style={{ margin: 0, fontSize: "0.8em" }}>
            Contact: {business.contact}
          </p>
        )}
      </div>

      <nav
        style={{
          display: "flex",
          backgroundColor: "#333",
          color: "#fff",
          padding: "0.5rem",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        {business.pages.map((page) => (
          <Link
            key={page.id}
            to={`/${domain}/${page.id}`}
            style={{
              color: "#fff",
              textDecoration: "none",
              padding: "0.5rem",
              borderBottom:
                activePage.id === page.id ? "2px solid #fff" : "none",
            }}
          >
            {page.title}
          </Link>
        ))}
      </nav>

      <div className="businesspage content max600">
        {/* If we have blocks... */}
        {activePage.blocks && activePage.blocks.length > 0 ? (
          activePage.blocks.map((block, idx) => {
            if (block.type === "text") {
              return (
                <p key={idx} style={{ marginBottom: "1rem", lineHeight: 1.5 }}>
                  {block.content}
                </p>
              );
            } else if (block.type === "title") {
              return (
                <h2 key={idx} style={{ marginBottom: "1rem", lineHeight: 1.4 }}>
                  {block.content}
                </h2>
              );
            } else if (block.type === "image") {
              return (
                <div key={idx} style={{ marginBottom: "1rem" }}>
                  <img
                    src={block.url}
                    alt="Content block"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      display: "block",
                    }}
                  />
                </div>
              );
            }
            return null;
          })
        ) : (
          // Fallback for old "content" & "images" fields if no blocks exist
          <>
            {activePage.images && activePage.images.length > 0 && (
              <div style={{ marginBottom: "1rem" }}>
                {activePage.images.map((imgUrl, idx) => (
                  <div key={idx} style={{ marginBottom: "1rem" }}>
                    <img
                      src={imgUrl}
                      alt="Page"
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        display: "block",
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
            {activePage.content &&
              activePage.content.split("\n").map((line, idx) => (
                <p key={idx} style={{ marginBottom: "1rem", lineHeight: 1.5 }}>
                  {line}
                </p>
              ))}
          </>
        )}
      </div>

      {/* Footer */}
      <footer
        style={{
          backgroundColor: "#fafafa",
          textAlign: "center",
          padding: "1rem",
          marginTop: "2rem",
          borderTop: "1px solid #ccc",
        }}
      >
        <p style={{ margin: 0 }}>
          &copy; {new Date().getFullYear()} {business.name}
        </p>
        <small>{`https://biashara.online/${domain}`}</small>
      </footer>
    </div>
  );
};

export default BusinessPage;
