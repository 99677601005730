import React, { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import { Navbar } from "../../components";
import "./Home.css";

const Home = () => {
  const [businesses, setBusinesses] = useState([]);

  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "businesses"));
        const data = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setBusinesses(data);
      } catch (error) {
        console.error("Error fetching businesses:", error);
      }
    };
    fetchBusinesses();
  }, []);

  return (
    <div className="home-container">
      <Navbar />
      <header className="home-header">
        <h1 className="home-title">
          biashara.online/<span>[yourbusiness]</span>
        </h1>
        <p className="home-subtitle">
          Empowering small businesses in Africa to build beautiful websites from
          their phones — no coding needed.
        </p>
        <div className="home-input-group">
          <input
            placeholder="Enter your business name"
            className="home-input"
          />
          <button className="home-button">Get Started</button>
        </div>
      </header>

      <section className="home-section">
        <h2 className="home-section-title">Why Choose Biashara.online?</h2>
        <div className="home-grid">
          <div className="home-card">
            <h3 className="home-card-title">Mobile First</h3>
            <p>
              Design and launch your business website directly from your phone.
            </p>
          </div>
          <div className="home-card">
            <h3 className="home-card-title">Easy to Use</h3>
            <p>
              No tech skills needed. Choose a template, add your details, and go
              live.
            </p>
          </div>
          <div className="home-card">
            <h3 className="home-card-title">Free & Affordable Plans</h3>
            <p>Start for free, then upgrade as your business grows.</p>
          </div>
        </div>
      </section>

      <section className="home-cta">
        <h2 className="home-cta-title">Launch Your Website Today</h2>
        <p className="home-cta-text">
          Join thousands of African entrepreneurs already using Biashara.online.
        </p>
        <button className="home-button large">Start Building</button>
      </section>

      {businesses.length > 0 && (
        <section className="business-carousel">
          <h2>Businesses That Use Biashara</h2>
          <div className="business-cards-container">
            {businesses.slice(0, 5).map((biz) => (
              <div className="business-card" key={biz.id}>
                <a
                  href={biz.domain}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="business-link"
                >
                  <h3>{biz.name}</h3>
                  <p>{biz.domain}</p>
                </a>
              </div>
            ))}
          </div>
        </section>
      )}

      <footer className="home-footer">
        &copy; {new Date().getFullYear()} Biashara.online — Empowering Africa’s
        entrepreneurs
      </footer>
    </div>
  );
};

export default Home;
