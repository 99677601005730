import React, { useEffect, useState } from "react";
import { auth, db } from "../../firebase";
import {
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate, Link } from "react-router-dom";
import { Navbar } from "../../components";

const Dashboard = () => {
  const [businesses, setBusinesses] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);

  // Modal for creating/editing the business doc
  const [showModal, setShowModal] = useState(false);
  const [newBusiness, setNewBusiness] = useState("");
  const [domain, setDomain] = useState("");
  const [editMode, setEditMode] = useState(false);
  const [editingId, setEditingId] = useState(null);

  // Basic fields
  const [blockContent, setBlockContent] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const [images, setImages] = useState([]);

  // Manage "pages"
  const [pages, setPages] = useState([]);
  const [selectedPageId, setSelectedPageId] = useState("");
  const [pageTitle, setPageTitle] = useState("");
  const [pageContent, setPageContent] = useState("");
  const [pageImages, setPageImages] = useState([]);

  // File upload states for the main business images
  const [fileToUpload, setFileToUpload] = useState(null);
  const [uploading, setUploading] = useState(false);

  // File upload states for page images
  const [pageFileToUpload, setPageFileToUpload] = useState(null);
  const [pageUploading, setPageUploading] = useState(false);

  const navigate = useNavigate();
  const storage = getStorage();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login");
      return;
    }
    const fetchBusinesses = async () => {
      const q = query(
        collection(db, "businesses"),
        where("owner", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const bizList = [];
      querySnapshot.forEach((docSnap) => {
        bizList.push({ id: docSnap.id, ...docSnap.data() });
      });
      setBusinesses(bizList);
      if (bizList.length > 0) {
        setSelectedBusiness(bizList[0]);
      }
    };
    fetchBusinesses();
  }, [navigate]);

  useEffect(() => {
    if (selectedBusiness) {
      setBlockContent(selectedBusiness.content || "");
      setContactInfo(selectedBusiness.contact || "");
      setImages(selectedBusiness.images || []);
      // Setup pages
      setPages(selectedBusiness.pages || []);
    }
  }, [selectedBusiness]);

  // If user picks a business from the dropdown or "Add new"
  const handleSelectBusiness = (docId) => {
    if (docId === "add_new") {
      setShowModal(true);
    } else {
      const found = businesses.find((biz) => biz.id === docId);
      setSelectedBusiness(found);
    }
  };

  // Create default pages on new business
  const defaultPages = [
    {
      id: "home",
      title: "Home",
      content: "Welcome to our website!\nWe are excited to have you here.",
      images: [],
    },
    {
      id: "about",
      title: "About",
      content: "All about our company.",
      images: [],
    },
  ];

  // We store doc ID as "domain"
  const sanitizeDomain = (input) =>
    input
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "-")
      .replace(/-+/g, "-")
      .replace(/^-+|-+$/g, "");

  const handleBusinessNameChange = (value) => {
    setNewBusiness(value);
    setDomain(sanitizeDomain(value));
  };

  // Create new business doc with default pages
  const handleAddBusiness = async () => {
    if (!newBusiness.trim() || !domain.trim()) return;
    const docId = domain.trim();
    const business = {
      id: docId,
      name: newBusiness.trim(),
      domain: docId + ".biashara.online",
      content: "",
      contact: "",
      images: [],
      pages: defaultPages, // <=== create 2 default pages
      owner: auth.currentUser.uid,
      published: false,
    };
    await setDoc(doc(db, "businesses", docId), business);
    setBusinesses((prev) => [...prev, business]);
    setSelectedBusiness(business);
    setNewBusiness("");
    setDomain("");
    setShowModal(false);
  };

  // Edit existing business doc (if renaming domain, remove old doc)
  const handleEditBusiness = async () => {
    if (!selectedBusiness) return;
    const oldDocId = editingId;
    const newDocId = domain.trim();
    const updatedBusiness = {
      ...selectedBusiness,
      id: newDocId,
      name: newBusiness.trim(),
      domain: newDocId + ".biashara.online",
    };
    if (oldDocId !== newDocId) {
      await deleteDoc(doc(db, "businesses", oldDocId));
    }
    await setDoc(doc(db, "businesses", newDocId), updatedBusiness);
    const updatedList = businesses.map((biz) =>
      biz.id === oldDocId ? updatedBusiness : biz
    );
    setBusinesses(updatedList);
    setSelectedBusiness(updatedBusiness);
    setEditMode(false);
    setShowModal(false);
    setNewBusiness("");
    setDomain("");
  };

  const openEditModal = (biz) => {
    setNewBusiness(biz.name);
    setDomain(biz.id);
    setEditingId(biz.id);
    setEditMode(true);
    setShowModal(true);
  };

  // Use this revised handleSaveContent to automatically merge any unsaved blocks
  // from the currently selected page into pages[], then update Firestore.

  const handleSaveContent = async () => {
    if (!selectedBusiness) return;

    // 1. Merge the currently selected page blocks/title into the pages array
    let updatedPages = [...pages];
    if (selectedPageId) {
      // Find and update the matching page
      updatedPages = updatedPages.map((p) =>
        p.id === selectedPageId
          ? { ...p, title: pageTitle, blocks: selectedPageBlocks }
          : p
      );
    }

    // 2. Create an updated business object with new pages, contact, images, etc.
    const updatedBusiness = {
      ...selectedBusiness,
      content: blockContent,
      contact: contactInfo,
      images: images,
      pages: updatedPages,
    };

    // 3. Write this updated business to Firestore
    await updateDoc(
      doc(db, "businesses", selectedBusiness.id),
      updatedBusiness
    );

    // 4. Update local state so UI reflects the changes
    const updatedList = businesses.map((biz) =>
      biz.id === selectedBusiness.id ? updatedBusiness : biz
    );
    setBusinesses(updatedList);
    setSelectedBusiness(updatedBusiness);

    alert("Business data saved!");
  };

  // "Publish" toggles the published field
  const handlePublish = async () => {
    if (!selectedBusiness) return;
    const docRef = doc(db, "businesses", selectedBusiness.id);
    const updatedBusiness = { ...selectedBusiness, published: true };
    await updateDoc(docRef, { published: true });
    const updatedList = businesses.map((biz) =>
      biz.id === selectedBusiness.id ? updatedBusiness : biz
    );
    setBusinesses(updatedList);
    setSelectedBusiness(updatedBusiness);
    alert(
      `Your website is now published at biashara.online/${selectedBusiness.id}`
    );
  };

  // Handle file select for main images
  const handleFileChange = (e) => {
    if (e.target.files?.length > 0) {
      setFileToUpload(e.target.files[0]);
    }
  };

  // Upload main business image
  const handleUploadImage = async () => {
    if (!fileToUpload || !selectedBusiness) return;
    try {
      setUploading(true);
      const fileName = `${Date.now()}_${fileToUpload.name}`;
      const fileRef = ref(
        storage,
        `businesses/${selectedBusiness.id}/${fileName}`
      );
      await uploadBytes(fileRef, fileToUpload);
      const downloadURL = await getDownloadURL(fileRef);
      setImages((prev) => [...prev, downloadURL]);
    } catch (err) {
      console.error("Image upload failed:", err);
    } finally {
      setUploading(false);
      setFileToUpload(null);
    }
  };

  // Remove from main images
  const handleRemoveImage = (index) => {
    setImages((prev) => prev.filter((_, i) => i !== index));
  };

  // ============== PAGE MANAGEMENT ==============

  const [selectedPageBlocks, setSelectedPageBlocks] = useState([]);

  const [newBlockType, setNewBlockType] = useState("text");
  const [newBlockContent, setNewBlockContent] = useState(""); // For text/title content
  const [newBlockFile, setNewBlockFile] = useState(null); // For image upload
  const [isUploadingBlockFile, setIsUploadingBlockFile] = useState(false);

  // Select page from the list or "new_page"
  const handleSelectPage = (pageId) => {
    if (pageId === "new_page") {
      setSelectedPageId("");
      setPageTitle("");
      setSelectedPageBlocks([]); // no blocks yet
    } else {
      const page = pages.find((p) => p.id === pageId);
      if (page) {
        setSelectedPageId(page.id);
        setPageTitle(page.title);
        // If the page has blocks, load them; else empty
        setSelectedPageBlocks(page.blocks || []);
      }
    }
  };
  // Move block up
  const moveBlockUp = (index) => {
    if (index === 0) return; // already at top
    const newBlocks = [...selectedPageBlocks];
    [newBlocks[index - 1], newBlocks[index]] = [
      newBlocks[index],
      newBlocks[index - 1],
    ];
    setSelectedPageBlocks(newBlocks);
  };

  // Move block down
  const moveBlockDown = (index) => {
    if (index === selectedPageBlocks.length - 1) return; // bottom
    const newBlocks = [...selectedPageBlocks];
    [newBlocks[index], newBlocks[index + 1]] = [
      newBlocks[index + 1],
      newBlocks[index],
    ];
    setSelectedPageBlocks(newBlocks);
  };

  // Remove a block
  const removeBlock = (index) => {
    const newBlocks = selectedPageBlocks.filter((_, i) => i !== index);
    setSelectedPageBlocks(newBlocks);
  };

  // Add a new block (text/title/image) depending on newBlockType
  const addNewBlock = async () => {
    if (!newBlockType) return;

    const newBlocks = [...selectedPageBlocks];

    if (newBlockType === "image") {
      // For image blocks, we upload a file to Storage first
      if (!newBlockFile) {
        alert("Please select an image file first.");
        return;
      }
      try {
        setIsUploadingBlockFile(true);
        const fileName = `${Date.now()}_${newBlockFile.name}`;
        const fileRef = ref(
          storage,
          `businesses/${selectedBusiness.id}/pages/${fileName}`
        );
        await uploadBytes(fileRef, newBlockFile);
        const downloadURL = await getDownloadURL(fileRef);

        newBlocks.push({ type: "image", url: downloadURL });
        setSelectedPageBlocks(newBlocks);

        // Reset
        setNewBlockFile(null);
        setNewBlockContent("");
      } catch (err) {
        console.error("Image block upload failed:", err);
      } finally {
        setIsUploadingBlockFile(false);
      }
    } else {
      // "text" or "title"
      if (!newBlockContent.trim()) {
        alert("Please enter some text for this block.");
        return;
      }
      newBlocks.push({
        type: newBlockType, // "text" or "title"
        content: newBlockContent,
      });
      setSelectedPageBlocks(newBlocks);

      // Reset
      setNewBlockContent("");
    }
  };

  const handleSavePage = () => {
    if (!pageTitle.trim()) {
      alert("Page title is required.");
      return;
    }

    let updatedPages = [...pages];
    if (!selectedPageId) {
      // new page
      const newId = sanitizeDomain(pageTitle);
      if (updatedPages.some((p) => p.id === newId)) {
        alert("A page with that ID already exists.");
        return;
      }
      updatedPages.push({
        id: newId,
        title: pageTitle.trim(),
        blocks: selectedPageBlocks, // store blocks here
      });
    } else {
      // update existing page
      updatedPages = updatedPages.map((p) =>
        p.id === selectedPageId
          ? { ...p, title: pageTitle, blocks: selectedPageBlocks }
          : p
      );
    }
    setPages(updatedPages);
    alert("Page saved locally. Don't forget to click 'Save All Changes'!");
  };

  // Remove a page from the array
  const handleRemovePage = () => {
    if (!selectedPageId) return;
    const confirmed = window.confirm(
      "Are you sure you want to delete this page?"
    );
    if (!confirmed) return;

    const updatedPages = pages.filter((p) => p.id !== selectedPageId);
    setPages(updatedPages);
    // Clear out form
    setSelectedPageId("");
    setPageTitle("");
    setPageContent("");
    setPageImages([]);
  };

  if (!auth.currentUser) return <p>Loading...</p>;

  return (
    <div>
      <Navbar />
      <div
        style={{ maxWidth: "900px", margin: "0 auto" }}
        className="main max600"
      >
        {/* <button onClick={handleLogout}>Log out</button> */}

        {/* Select a business */}
        <h2>My Businesses</h2>
        <select
          value={selectedBusiness ? selectedBusiness.id : ""}
          onChange={(e) => handleSelectBusiness(e.target.value)}
        >
          {businesses.length === 0 ? (
            <option>No businesses registered yet</option>
          ) : (
            businesses.map((biz) => (
              <option key={biz.id} value={biz.id}>
                {biz.name}
              </option>
            ))
          )}
          <option value="add_new">➕ Add a new business</option>
        </select>

        {/* Edit Business Modal */}
        {showModal && (
          <div
            style={{
              border: "1px solid #ccc",
              padding: "1rem",
              marginTop: "1rem",
            }}
          >
            <h3>{editMode ? "Edit Business" : "Add New Business"}</h3>
            <input
              placeholder="Business name"
              value={newBusiness}
              onChange={(e) => handleBusinessNameChange(e.target.value)}
              style={{ display: "block", marginBottom: "10px" }}
            />
            <input
              placeholder="Business ID"
              value={domain}
              onChange={(e) => setDomain(sanitizeDomain(e.target.value))}
              style={{ display: "block", marginBottom: "10px" }}
            />
            <div style={{ marginTop: "10px" }}>
              <button
                onClick={editMode ? handleEditBusiness : handleAddBusiness}
              >
                {editMode ? "Save Changes" : "Add Business"}
              </button>
              <button
                onClick={() => setShowModal(false)}
                style={{ marginLeft: "10px" }}
              >
                Cancel
              </button>
            </div>
          </div>
        )}

        {selectedBusiness && (
          <div>
            <p>
              View at:{" "}
              <Link to={`/${selectedBusiness.id}`} target="_blank">
                biashara.online/{selectedBusiness.id}
              </Link>
            </p>
            <button onClick={() => openEditModal(selectedBusiness)}>
              Edit Business Info
            </button>
            <hr />
            {/* Basic Content */}
            <h2>Global Content</h2>
            <input
              style={{ width: "100%", marginBottom: "10px" }}
              value={blockContent}
              onChange={(e) => setBlockContent(e.target.value)}
              placeholder="Text content for your homepage or legacy block..."
            />
            <h3>Contact Info</h3>

            <input
              style={{ width: "100%", marginBottom: "10px" }}
              value={contactInfo}
              onChange={(e) => setContactInfo(e.target.value)}
              placeholder="Phone, email, etc."
            />
            <h3>Main Images</h3>
            <div style={{ marginBottom: "10px" }}>
              <input type="file" onChange={handleFileChange} />
              <button
                onClick={handleUploadImage}
                disabled={!fileToUpload || uploading}
              >
                {uploading ? "Uploading..." : "Upload Image"}
              </button>
            </div>
            {images.length > 0 && (
              <ul>
                {images.map((img, idx) => (
                  <li key={idx}>
                    <a href={img} target="_blank" rel="noopener noreferrer">
                      {img}
                    </a>
                    <button
                      onClick={() => handleRemoveImage(idx)}
                      style={{ marginLeft: "10px" }}
                    >
                      Remove
                    </button>
                  </li>
                ))}
              </ul>
            )}
            <hr />
            <br />
            <h2>My Website Pages</h2>
            <select
              value={selectedPageId || ""}
              onChange={(e) => handleSelectPage(e.target.value)}
            >
              <option value="">Select a page...</option>
              {pages.map((p) => (
                <option key={p.id} value={p.id}>
                  {p.title} ({p.id})
                </option>
              ))}
              <option value="new_page">➕ Create New Page</option>
            </select>
            {/* Only show block editor if a page is chosen */}
            {!selectedPageId ? (
              <p style={{ marginTop: "1rem", fontStyle: "italic" }}>
                Please select or create a page to add blocks.
              </p>
            ) : (
              <div
                style={{
                  border: "1px solid #ccc",
                  padding: "1rem",
                  marginTop: "1rem",
                }}
              >
                <h3>Blocks</h3>

                {selectedPageBlocks.map((block, index) => (
                  <div
                    key={index}
                    style={{
                      border: "1px solid #ddd",
                      marginBottom: "0.5rem",
                      padding: "0.5rem",
                    }}
                  >
                    {block.type === "text" && (
                      <textarea
                        rows={3}
                        style={{ width: "100%" }}
                        value={block.content}
                        onChange={(e) => {
                          const newBlocks = [...selectedPageBlocks];
                          newBlocks[index].content = e.target.value;
                          setSelectedPageBlocks(newBlocks);
                        }}
                      />
                    )}
                    {block.type === "title" && (
                      <input
                        style={{
                          width: "100%",
                          fontWeight: "bold",
                          fontSize: "1.2rem",
                        }}
                        value={block.content}
                        onChange={(e) => {
                          const newBlocks = [...selectedPageBlocks];
                          newBlocks[index].content = e.target.value;
                          setSelectedPageBlocks(newBlocks);
                        }}
                      />
                    )}
                    {block.type === "image" && (
                      <div>
                        <img
                          src={block.url}
                          alt="Block"
                          style={{
                            maxWidth: "100%",
                            display: "block",
                            marginBottom: "0.5rem",
                          }}
                        />
                        <p>{block.url}</p>
                      </div>
                    )}
                    <div style={{ marginBottom: "0.5rem" }}>
                      <button onClick={() => moveBlockUp(index)}>↑</button>{" "}
                      <button onClick={() => moveBlockDown(index)}>↓</button>{" "}
                      <button onClick={() => removeBlock(index)}>Remove</button>
                    </div>
                  </div>
                ))}

                <div
                  style={{
                    marginTop: "1rem",
                    borderTop: "1px solid #ccc",
                    paddingTop: "1rem",
                  }}
                >
                  <h3>Add Block Content</h3>
                  <label>Block Type:</label>
                  <select
                    value={newBlockType}
                    onChange={(e) => setNewBlockType(e.target.value)}
                  >
                    <option value="text">Text</option>
                    <option value="title">Title</option>
                    <option value="image">Image</option>
                  </select>

                  {newBlockType === "image" ? (
                    <div style={{ marginTop: "0.5rem" }}>
                      <label>Select Image File:</label>
                      <input
                        type="file"
                        style={{ display: "block", marginTop: "0.5rem" }}
                        onChange={(e) => {
                          if (e.target.files?.length > 0) {
                            setNewBlockFile(e.target.files[0]);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <div style={{ marginTop: "0.5rem" }}>
                      <label>Text Content:</label>
                      <textarea
                        rows="4"
                        style={{
                          display: "block",
                          width: "100%",
                          marginTop: "0.5rem",
                        }}
                        value={newBlockContent}
                        onChange={(e) => setNewBlockContent(e.target.value)}
                      />
                    </div>
                  )}

                  <button
                    onClick={addNewBlock}
                    disabled={isUploadingBlockFile}
                    style={{ marginTop: "0.5rem" }}
                  >
                    {isUploadingBlockFile ? "Uploading..." : "Add Block"}
                  </button>
                </div>
              </div>
            )}
            <hr />
            {/* Final Save */}
            <button onClick={handleSaveContent} style={{ marginRight: "10px" }}>
              Save All Changes
            </button>
            <button onClick={handlePublish}>Publish</button>
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
