import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="home-navbar">
      <div className="navbar-brand">
        <Link to="/" className="brand-link">
          Biashara.online
        </Link>
      </div>
      <div className="navbar-menu">
        <Link to="/dashboard" className="navbar-link">
          My Business
        </Link>
      </div>
    </nav>
  );
};

export default Navbar;
